<template>
  <div class="app-content content ">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <h2 class="content-header-title mb-0">
                Peers report {{ convo.id ? `(${convo.title})` : '' }}
              </h2>
            </div>
          </div>
        </div>
        <div class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none">
          <div class="mb-1 breadcrumb-right">
            <a
              class="btn btn-icon btn-secondary"
              :href="$route.params.id ? $router.resolve({ name: 'admin.calls.promotion.view', params: { id: $route.params.id } }).href : $router.resolve({ name: 'admin.calls.promotion.index'}).href"
              target="_blank"
            ><i data-feather="chevron-left" /></a>
          </div>
        </div>
      </div>
      <div class="content-body content-body--senior-call">
        <div class="row">
          <div class="col-12">
            <div class="card card--table">
              <div class="card-header d-flex align-items-center justify-content-between">
                <div>
                  <h4><strong>{{ totalPeers }}</strong> peers</h4>
                </div>
                <div>
                  <span
                    class="dis-ib"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Open filters"
                  ><a
                    class="btn btn-icon btn-icon rounded-circle btn-flat-secondary"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasFilters"
                    aria-controls="offcanvasFilters"
                    @click="$store.dispatch('modals/toggleConvoFilters', true)"
                  ><i data-feather="filter" /></a></span>
                  <!-- enlace al excel de ejemplo que se monta Sonia -->
                  <!-- <a
                    href="Promo23-Peers.xlsx"
                    target="_blank"
                    class="btn btn-icon btn-icon rounded-circle btn-flat-secondary"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Export to Excel"
                  ><i data-feather="file" /></a> -->
                  <!--  -->
                  <span
                    class="dis-ib"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Table settings"
                    @click="$store.dispatch('modals/toggleTableSettings', true)"
                  ><a
                    href="#"
                    class="btn btn-icon btn-icon rounded-circle btn-flat-secondary"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasTableSettings"
                    aria-controls="offcanvasTableSettings"
                  ><i data-feather="settings" /></a></span>
                </div>

              </div>
              <div
                id="usedFilters"
                class="card-body"
              >
                Applied Filters:
                <span
                  v-for="filter, index in watchFilters"
                  :key="`filters-${index}`"
                  class="badge badge-light-dark ms-1"
                >
                  <template v-if="Array.isArray(filter)">
                    <template
                      v-for="str in filter"
                    >
                      {{ str.name }}
                      <a
                        :key="`filters2-${str.name}`"
                        class="ms-1 text-black"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Delete filter"
                        @click="deleteFilter(index)"
                      ><i data-feather="x" /></a>
                    </template>
                  </template>
                  <template v-else-if="typeof filter === 'object' && filter">
                    {{ filter.name }}
                    <a
                      class="ms-1 text-black"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Delete filter"
                      @click="deleteFilter(index)"
                    ><i data-feather="x" /></a>
                  </template>
                  <template v-else-if="filter">
                    {{ filter }}
                    <a
                      class="ms-1 text-black"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="Delete filter"
                      @click="deleteFilter(index)"
                    ><i data-feather="x" /></a>
                  </template>
                </span>
              </div>
              <template v-if="!loading">
                <div class="table-responsive mb-0">
                  <table class="table ">
                    <thead>
                      <tr>
                        <template v-for="field in fields">
                          <th
                            v-if="field.checked"
                            :key="`field-${field.id}`"
                          >
                            {{ field.name }}
                          </th>
                        </template>
                        <th class="text-end">
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <template
                      v-for="researcher in peers"
                    >
                      <tbody :key="`peer-${researcher.id}`">
                        <tr
                          class="table-secondary"
                        >
                          <td>
                            <strong>
                              <a
                                v-b-toggle="`collapseResearcher-${researcher.id}`"
                                class="text-dark"
                              ><i data-feather="minimize-2" /> {{ researcher.researcher }}</a>
                            </strong>
                          </td>
                          <td>
                            {{ researcher.area ? researcher.area.code : '--' }}
                          </td>
                          <td>
                            {{ researcher.host ? researcher.host.code : '--' }}
                          </td>
                          <td>
                            {{ researcher.erc ? researcher.erc.code : '--' }}
                          </td>
                          <td />
                          <td />
                          <td />
                          <td />
                          <td>
                            <div class="row">
                              <div class="col-4">
                                <strong class="me-1">{{ researcher.peers ? researcher.peers.length : 0 }}</strong>
                              </div>
                              <div class="col-4">
                                <span
                                  class="badge bg-success ms-1"
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top"
                                  title="Completed"
                                >{{ researcher.peers.filter(e => e.status_id == 6 ).length }}</span>
                              </div>
                              <div class="col-4">
                                <span
                                  class="badge bg-warning ms-1"
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top"
                                  title="Pending"
                                >{{ researcher.peers.filter(e => e.status_id == 4 ).length }}</span>
                              </div>
                              <div class="col-4" />
                              <div class="col-4">
                                <span
                                  class="badge bg-info ms-1"
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top"
                                  title="Pending"
                                >{{ researcher.peers.filter(e => e.status_id == 2 ).length }}</span>
                              </div>
                              <div class="col-4">
                                <span
                                  class="badge bg-danger ms-1"
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top"
                                  title="Pending"
                                >{{ researcher.peers.filter(e => e.status_id == 5 ).length }}</span>
                              </div>
                            </div>
                          </td>
                          <td />
                          <td class="text-end">
                            <a
                              class="btn btn-icon btn-light-secondary"
                              @click="openAddPeer(researcher)"
                            ><span
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Add peers"
                            ><i data-feather="plus" /></span></a>
                          </td>
                        </tr>
                      </tbody>
                      <b-collapse
                        :id="`collapseResearcher-${researcher.id}`"
                        :key="`collapseesearcher-${researcher.id}`"
                        tag="tbody"
                        class="table"
                      >
                        <tr
                          v-for="peer, index in researcher.peers"
                          :key="`${peer.peer}${index}`"
                        >
                          <td />
                          <td />
                          <td />
                          <td>{{ peer.areas ? peer.areas.map(e => e.name).join(', ') : '-' }}</td>
                          <td>{{ peer.added_by }}</td>
                          <td>{{ peer.first_name }}</td>
                          <td>{{ peer.last_name }}</td>
                          <td>{{ peer.institution }}</td>
                          <td v-html="peer.status" />
                          <td />
                          <td>
                            <b-dropdown
                              variant="link"
                              no-caret
                              toggle-class="p-0"
                              right
                            >
                              <template #button-content>
                                <i data-feather="more-vertical" />
                              </template>
                              <div
                                class="btn-group dropup dropdown-icon-wrapper d-none d-sm-block"
                              >
                                <b-dropdown-item
                                  v-if="peer.status_id == 6"
                                  :href="$router.resolve({name: 'researcher.administration.promotion-history.see-evaluation', query: { user: peer.pivot.researcher_id, convo: peer.pivot.convo_id, actAs: $route.query.actAs}}).href"
                                  target="_blank"
                                >
                                  <i
                                    class="me-50"
                                    data-feather="eye"
                                  /> View form evaluation
                                </b-dropdown-item>
                                <b-dropdown-item
                                  @click="changeStatus(peer)"
                                >
                                  <i
                                    class="me-50"
                                    data-feather="edit-3"
                                  /> Change status
                                </b-dropdown-item>
                              </div>
                            </b-dropdown>
                          </td>
                        </tr>
                      </b-collapse>
                    </template>
                  </table>
                </div>
                <ConvoFilter
                  :filter-path="'users/filterPeers'"
                >
                  <template #filters>
                    <div class="mb-1">
                      <label
                        for=""
                        class="form-label"
                      >Promotion</label>
                      <v-select
                        v-model="filters.year"
                        label="name"
                        :options="[
                          { name: '2024', value: 2024 },
                          { name: '2023', value: 2023 },
                          { name: '2022', value: 2022 },
                          { name: '2021', value: 2021 },
                          { name: '2020', value: 2020 },
                          { name: '2019', value: 2019 },
                        ]"
                        :get-option-key="option => option.id"
                        @input="saveFilter({ customLabel: true, name: `Promotion: ${$event ? $event.name : ''}`, value: $event }, 'year')"
                      />
                    </div>
                    <hr>
                    <div class="mb-1">
                      <label
                        for=""
                        class="form-label"
                      >Researcher</label>
                      <v-select
                        v-model="filters.user"
                        label="name"
                        :options="users"
                        :get-option-key="option => option.id"
                        @search="onSearch({ name: $event }, 'users/fetchFakeUsers')"
                        @input="saveFilter({ customLabel: true, name: `Researcher: ${$event ? $event.name : ''}`, value: $event }, 'user')"
                      />
                    </div>
                    <div class="mb-1">
                      <label
                        for=""
                        class="form-label"
                      >Area</label>
                      <v-select
                        v-model="filters.area"
                        label="name"
                        :options="areas"
                        :get-option-key="option => option.id"
                        @input="saveFilter({ customLabel: true, name: `Area: ${$event ? $event.name : ''}`, value: $event }, 'area')"
                      />
                    </div>
                    <!-- <div class="mb-1">
                      <label
                        for=""
                        class="form-label"
                      >Proposed by</label>
                      <select
                        id=""
                        name=""
                        class="form-select select2"
                        multiple
                      >
                        <option
                          value=""
                          selected
                        >
                          All
                        </option>
                        <option value="researcher">
                          Researcher
                        </option>
                        <option value="pc_member">
                          pc member 1
                        </option>
                        <option value="">
                          pc member 2
                        </option>
                        <option value="">
                          Sonia
                        </option>
                        <option value="">
                          xxx
                        </option>
                      </select>
                    </div> -->
                    <div class="mb-1">
                      <label
                        for=""
                        class="form-label"
                      >Peer name</label>
                      <input
                        v-model="filters.peer_name"
                        type="text"
                        class="form-control"
                        @change="saveFilter({ customLabel: true, name: `Peer name: ${$event.target.value}`, value: `${$event.target.value}` }, 'peer_name')"
                      >
                    </div>
                    <div class="mb-1">
                      <label
                        for=""
                        class="form-label"
                      >Peer institution</label>
                      <input
                        v-model="filters.peer_institution"
                        type="text"
                        class="form-control"
                        @change="saveFilter({ customLabel: true, name: `Peer institution: ${$event.target.value}`, value: `${$event.target.value}` }, 'peer_institution')"
                      >
                    </div>
                    <div class="mb-1">
                      <label
                        for=""
                        class="form-label"
                      >Status</label>
                      <v-select
                        v-model="filters.status"
                        label="name"
                        :options="[
                          { name: 'Pending send email', value: 1 },
                          { name: 'Pending response', value: 2 },
                          { name: 'Not answered', value: 3 },
                          { name: 'Accepted', value: 4 },
                          { name: 'Declined', value: 5 },
                          { name: 'Completed', value: 6 },
                        ]"
                        :get-option-key="option => option.value"
                        @input="saveFilter({ customLabel: true, name: `Status: ${$event ? $event.name : ''}`, value: $event }, 'status')"
                      />
                    </div>
                  </template>
                </ConvoFilter>
                <TableColumns />
              </template>
              <div
                v-else
                class="loading custom-loading m-auto"
              >
                <div class="effect-1 effects" />
                <div class="effect-2 effects" />
                <div class="effect-3 effects" />
              </div>
              <div
                class="card-body"
              >
                <div class="d-flex align-items-center justify-content-between">
                  <Paginator
                    :total="totalPeers"
                    :store="'-'"
                    @loading="setLoading"
                  />
                  <div>
                    <select
                      v-model="perPage"
                      class="form-select"
                      @change="setPerPage"
                    >
                      <option value="15">
                        15 items per page
                      </option>
                      <option value="50">
                        50 items per page
                      </option>
                      <option value="75">
                        75 items per page
                      </option>
                      <option value="10000">
                        All items per page
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ItemInfoSheet :table-id="1">
        <template #info-sheet-title>
          Change peer status - {{ selectedPeer ? selectedPeer.peer : '' }}
        </template>
        <template #info-sheet>
          <div
            v-if="selectedPeer"
            class="offcanvas-body"
          >
            <div class="mb-1">
              <label
                for=""
                class="form-label"
              >Select status</label>
              <v-select
                v-model="peerStatus"
                label="name"
                :options="[
                  {name: 'Pending to send email', value: 1},
                  {name: 'Pending response', value: 2},
                  {name: 'Not answered', value: 3},
                  {name: 'Accepted', value: 4},
                  {name: 'Declined', value: 5},
                  {name: 'Completed', value: 6},
                  {name: 'Blacklisted', value: 999},
                ]"
                :get-option-key="option => option.value"
              />
            </div>
            <hr>
            <div
              v-if="peerStatus && peerStatus.value == 5"
              class="form-group"
            >
              <label
                class="form-label"
              >Decline reason</label>
              <textarea
                v-model="declinedReason"
                class="form-control"
              />
            </div>
          </div>
          <div class="offcanvas-footer mt-auto">
            <button
              type="button"
              class="btn btn-success mb-1 d-grid w-100"
              @click="sendPeerStatus"
            >
              Apply
            </button>
          </div>
        </template>
      </ItemInfoSheet>
      <ItemInfoSheet>
        <template #info-sheet-title>
          Add peer - {{ selectedItem.researcher }}
        </template>
        <template #info-sheet>
          <div class="offcanvas-body">
            <div class="mb-1">
              <label
                for=""
                class="form-label"
              >Select a peer</label>
              <v-select
                v-model="selectedPeer"
                label="name"
                :options="evaluators"
                :get-option-key="option => option.id"
                @search="onSearch({name_institution_mail: $event}, 'users/filterEvaluators')"
              />
            </div>
            <div class="mb-2">
              <button
                class="btn btn-sm btn-dark"
                @click="managePeers(selectedPeer.id, 1)"
              >
                Add
              </button>
            </div>
            <hr>
            <div
              v-for="peer, index in selectedItem.peers"
              :key="`${peer.id}-${index}`"
              class="mb-2"
            >
              <p>
                <a
                  v-b-toggle="`collapsePeer-${peer.id}`"
                  class="text-dark"
                ><strong>{{ peer.last_name }}, {{ peer.first_name }}</strong></a>
                <span
                  class="dis-ib float-end"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Delete peer"
                >
                  <a
                    class="text-danger"
                    @click="managePeers(peer.id, 2)"
                  ><i data-feather="trash-2" /></a>
                </span>
              </p>
              <b-collapse
                :id="`collapsePeer-${peer.id}`"
                class="collapse"
              >
                <p><small>Proposed by: <strong>Researcher</strong></small></p>
                <table class="table table-condensed">
                  <thead>
                    <tr>
                      <th>Area</th>
                      <th>Institution</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{{ peer.areas ? peer.areas.map(e => e.name).join(', ') : '-' }}</td>
                      <td>{{ peer.institution }}</td>
                      <td v-html="peer.status" />
                    </tr>
                  </tbody>
                </table>
              </b-collapse>
            </div>
          </div>
          <div class="offcanvas-footer mt-auto">
            <button
              type="button"
              class="btn btn-success mb-1 d-grid w-100"
            >
              Apply
            </button>
          </div>
        </template>
      </ItemInfoSheet>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { BCollapse, BDropdown, BDropdownItem } from 'bootstrap-vue'
import vSelect from 'vue-select'
import ItemInfoSheet from '@/views/back/partials/offcanvas/ItemInfoSheet.vue'
import Vue from 'vue'
import ConvoFilter from '../../partials/offcanvas/ConvoFilter.vue'
import TableColumns from '../../partials/offcanvas/TableColumns.vue'
import Paginator from '../../partials/Paginator.vue'

export default {
  components: {
    Paginator,
    BCollapse,
    ConvoFilter,
    TableColumns,
    vSelect,
    ItemInfoSheet,
    BDropdown,
    BDropdownItem,
  },
  props: {
    type: { type: String, required: false, default: '' },
  },
  data() {
    return {
      peerStatus: null,
      declinedReason: null,
      selectedPeer: null,
      loading: true,
      selectedItem: {},
      sortedField: 'Name',
      defaultFields: [
        {
          name: 'Researcher',
          checked: true,
          order: 1,
        },
        {
          name: 'Area',
          checked: true,
          order: 2,
        },
        {
          name: 'Host',
          checked: true,
          order: 3,
        },
        {
          name: 'ERC',
          checked: true,
          order: 4,
        },
        {
          name: 'Proposed by',
          checked: true,
          order: 5,
        },
        {
          name: 'Name',
          checked: true,
          order: 6,
        },
        {
          name: 'Surname',
          checked: true,
          order: 7,
        },
        {
          name: 'Institution',
          checked: true,
          order: 8,
        },
        {
          name: 'Status',
          checked: true,
          order: 9,
        },
        {
          name: 'Overall Qualif',
          checked: true,
          order: 10,
        },
      ],
      sortMapping: {
        Researcher: 'peer',
        Area: 'areas',
        'Proposed by': 'added_by.name',
        Name: 'first_name',
        Surname: 'last_name',
        Institution: 'institution',
        Status: 'pivot.status',
        'Overall Qualif': '',
      },
    }
  },
  computed: {
    ...mapGetters({
      users: 'users/fakeUsers',
      convo: 'convo/convo',
      roles: 'roles/allRoles',
      peers: 'users/peers',
      totalPeers: 'users/totalPeers',
      filters: 'filters/filters',
      watchFilters: 'filters/watchFilters',
      fields: 'modals/tableSettingsFields',
      areas: 'areas/areas',
      evaluators: 'users/evaluators',
      perPage: 'pagination/perPage',
    }),
  },
  async mounted() {
    this.$store.dispatch('convo/removeConvo')

    if (this.areas.length === 0) {
      this.$store.dispatch('areas/filter', this.$store.getters['filters/filters'])
    }

    if (this.$route.params.id) {
      await this.$store.dispatch('convo/fetchSpecific', this.$route.params.id)
    }

    await this.$store.dispatch('users/filterPeers', this.filters)

    await this.$store.dispatch('modals/fetchUserFields', 'peers_report')
    if (!this.fields || this.fields.length === undefined) { // If user doesnt have default fields, load the true default
      await this.$store.dispatch('modals/fetchFields', {
        fields: this.defaultFields,
        table: 'peers_report',
      })
    }

    this.loading = false
    setTimeout(() => {
      // eslint-disable-next-line no-undef
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 500)
  },
  methods: {
    async sendPeerStatus() {
      await this.$store.dispatch('users/changePeerStatus', {
        peerId: this.selectedPeer.id,
        researcherId: this.selectedPeer.pivot.researcher_id,
        status: this.peerStatus.value,
        declinedReason: this.declinedReason,
      })
      this.$store.dispatch('modals/toggleInfoItemSheet', false)
      await this.$store.dispatch('users/getPeers', null)
      setTimeout(() => {
        // eslint-disable-next-line no-undef
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 200)
    },
    async setLoading(loading) {
      if (loading) {
        this.loading = true
        await this.$store.dispatch('users/filterPeers', this.filters)
        this.loading = false
        setTimeout(() => {
          // eslint-disable-next-line no-undef
          feather.replace({
            width: 14,
            height: 14,
          })
        }, 200)
      }
    },
    async setPerPage(perPage) {
      this.selectedItems = []
      this.loading = true
      this.$store.dispatch('pagination/clearPage')
      await this.$store.dispatch('pagination/savePerPage', perPage.target.value)
      await this.$store.dispatch('users/getPeers', null)

      this.loading = false
      setTimeout(() => {
        // eslint-disable-next-line no-undef
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 200)
    },
    saveFilter(value, field) {
      if (!value || (!value.value || (value.value === 'null' || (Array.isArray(value.value) && !value.value.length)))) {
        this.$store.dispatch('filters/deleteFilters', field)
      } else {
        this.$store.dispatch('filters/saveFilters', {
          value,
          field,
        })
      }
    },
    async onSearch(search, store) {
      const s = search
      s.active = true
      if ((s.name && s.name !== '' && s.name.length > 2) || (s.name_institution_mail && s.name_institution_mail !== '' && s.name_institution_mail.length > 2)) {
        await this.$store.dispatch(store, s)
      }
    },
    openAddPeer(researcher) {
      this.$store.dispatch('modals/toggleInfoItemSheet', true)
      setTimeout(() => {
        // eslint-disable-next-line no-undef
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 200)
      this.selectedItem = researcher
    },
    async deleteFilter(index) {
      this.loading = true
      this.$store.dispatch('filters/deleteFilters', index)
      await this.$store.dispatch('users/filterPeers', this.filters)
      this.loading = false

      setTimeout(() => {
        // eslint-disable-next-line no-undef
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 200)

      // const { filters } = this

      // if (!areaId) {
      //   filters.candidate = null
      // } else {
      //   filters.areas.splice(filters.areas.findIndex(x => x.id === areaId), 1)
      // }

      // this.$store.dispatch('convo/filter', this.filters)
    },
    changeStatus(peer) {
      this.selectedPeer = peer
      this.$store.dispatch('modals/toggleInfoItemSheet', true)
      this.$store.dispatch('modals/toggleTableClicked', 1)
    },
    async managePeers(peerId, assign) {
      Vue.swal({
        title: 'Perform the actions on the selected peer?',
        html: '',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Yes, apply!',
      }).then(result => {
        if (result.isConfirmed) {
          this.$store.dispatch('users/setPeers', { actions: { assign }, itemIds: [peerId], researcherId: this.selectedItem.id }).then(async () => {
            await this.$store.dispatch('users/filterPeers', this.filters)
            this.$toastr.success('', 'Actions applied successfully!')
            this.$store.dispatch('modals/toggleInfoItemSheet', false)
            setTimeout(() => {
              // eslint-disable-next-line no-undef
              feather.replace({
                width: 14,
                height: 14,
              })
            }, 200)
          })
        }
      })
    },
  },
}
</script>
